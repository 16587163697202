import { MutationTree } from 'vuex';
import { IGlobalState } from './global-type';
import type { ISystemConfig } from '@/types/system';

export const mutations: MutationTree<IGlobalState> = {
  setSystemConfig(state, data: ISystemConfig) {
    state.systemConfig = data;
  },

  showLoginDialog(state, data = {}) {
    state.loginDialog.show = true;
    state.loginDialog.page = data.page || 1;
    state.loginDialog.resolve = data.resolve;
    state.loginDialog.reject = data.reject;
  },
  closeLoginDialog(state) {
    state.loginDialog.show = false;
  },
  setLoginPage(state, page: number) {
    state.loginDialog.page = page || 1;
  },

  showRechargeDialog(state, data = {}) {
    state.rechargeDialog.show = true;
    state.rechargeDialog.singlePayInfo = data.singlePayInfo;
    state.rechargeDialog.hidePointPay = data.hidePointPay;
    state.rechargeDialog.resolve = data.resolve;
    state.rechargeDialog.reject = data.reject;
  },
  hideRechargeDialog(state) {
    state.rechargeDialog.show = false;
  },
};
