import { getUrlSearchParamsObj } from '@/utils/utils';

// 处理来源埋点相关
export default {
  init() {
    const { source } = getUrlSearchParamsObj();
    if (typeof source === 'string' && source) {
      window.localStorage.setItem('page_source', source.substring(0, 50));
    }
  },
  getSource() {
    const source = getUrlSearchParamsObj().source || window.localStorage.getItem('page_source');
    return source || '';
  },
};
