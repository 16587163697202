import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'ant-design-vue';
import source from '../event-track/source';

import { userStore } from '@/store/modules/user/user-store';
import { HmacSHA1, enc } from 'crypto-js';

axios.defaults.baseURL = `https://${process.env.VUE_APP_BASE_URL}`;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';

// 创建axios请求
const http = axios.create({
  // 设置超时时间
  timeout: 1000 * 30,
  // 设置默认请求头
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

const APPID = 'eb6f2c5caab5fc11965c3ca19728bd16';

// 生成请求字符串
const getAuthStr = (url: string | undefined, gmtDateStr: string) => {
  const access_key = 'v0w6_7alzEk0wCJH';
  const secret_key = 'j1iBHD54MWUj8gGBmkf6FnzDdQm4_RaR';
  const appid = APPID;
  let str = '';
  if (url && url.indexOf('/sys/') > -1) {
    str = `${access_key}\n${appid}\n${gmtDateStr}`;
  } else {
    str = `${access_key}\n${gmtDateStr}\n${userStore.state.token || ''}`;
  }

  const auth = enc.Base64.stringify(HmacSHA1(str, secret_key));
  return `Basic ${auth}`;
};

http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // 请求头带上token
    if (config.headers) {
      config.headers['X-Appid'] = APPID;
      config.headers['X-token'] = userStore.state.token || '';
      const gmtDateStr = new Date().toGMTString();
      config.headers['X-date'] = gmtDateStr;
      config.headers['Authorization'] = getAuthStr(config.url, gmtDateStr);
      if (source.getSource()) config.headers['source'] = source.getSource();
    }
    return config;
  },
  (error) => {
    // 请求出错时的处理
    return Promise.reject(error);
  }
);

// 响应拦截，所有的相应通过此处
http.interceptors.response.use(
  (response: AxiosResponse) => {
    // 任何位于2xx范围内的状态码都会导致此功能触发

    // 任何位于2xx范围内的状态码都会导致此功能触发
    if (response.data && response.data.ret !== 0) {
      // ret不是0，代表返回了200状态，但是内容是报错
      if (!response.config.customParams?.hideToast) message.error(response.data.msg);
      return Promise.reject(response.data);
    } else if (response.data && response.data.body) {
      // 成功,并且是后台返回的数据
      return response.data.body;
    } else {
      // 成功不是后台的接口，直接返回data
      return response.data;
    }
  },
  (error) => {
    // 任何超出2xx范围的状态码都会触发此功能

    if (error.response) {
      if (error.response.status === 400) {
        if (!error.response.config.customParams?.hideToast) message.error('请求参数错误');
      }

      if (error.response.status === 401) {
        if (!error.response.config.customParams?.hideToast) message.error('登录已过期');
        userStore.dispatch('userLogout');
      }

      if (error.response.status === 404) {
        if (!error.response.config.customParams?.hideToast) message.error('接口不存在');
      }

      if (error.response.status === 500) {
        if (!error.response.config.customParams?.hideToast) message.error('服务器错误');
      }

      if (error.response.status === 501) {
        if (!error.response.config.customParams?.hideToast) message.error('接口未实现');
      }

      if (error.response.status === 503) {
        if (!error.response.config.customParams?.hideToast) message.error('服务不可用');
      }
    } else {
      console.log(JSON.stringify(error));
      if (!error.response.config.customParams?.hideToast) message.error('请求失败');
    }

    return Promise.reject(error);
  }
);

export default http;
