import sensorsdata from 'sa-sdk-javascript';
import source from '../event-track/source';

const env = process.env.VUE_APP_ENV;

// doc 地址 https://manual.sensorsdata.cn/sa/latest/sdk-web-57999858.html
// 拍博士目前使用的是多对一的用户方案
sensorsdata.init({
  server_url: `https://sensorapi.666visa.cn/sa?project=${env === 'prod' ? 'pbs' : 'default'}`,
  name: 'sensorsdata',
  is_track_single_page: true,
  heatmap: {
    // 是否开启点击图，默认 default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭
    clickmap: 'default',
    // 是否开启触达注意力图，默认 default 表示开启，自动采集 $WebStay 事件，可以设置 'not_collect' 表示关闭
    scroll_notice_map: 'default',
  },
  show_log: false,
});

sensorsdata.registerPage({
  channel_id: '',
  product: 'pc',
  platform: 'cn_pc',
  source() {
    return source.getSource();
  },
});

sensorsdata.quick('autoTrack');
